var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"bordered":false}},[_c('page-header-wrapper',{attrs:{"Style":{ padding: '3px 10px'},"title":false}}),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 },"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"lg":16,"md":24}},[_c('a-form-item',{attrs:{"label":"SMTP服务器"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'smtpIp',
              { rules: [{ required: true, message: '请输入邮件服务器IP地址' }] } ]),expression:"[\n              'smtpIp',\n              { rules: [{ required: true, message: '请输入邮件服务器IP地址' }] },\n            ]"}],attrs:{"placeholder":"请输入SMTP服务器"}})],1),_c('a-form-item',{attrs:{"label":"SMTP服务器端口"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'smtpPort',
              { rules: [{ required: true, message: '请输入SMTP端口' }] } ]),expression:"[\n              'smtpPort',\n              { rules: [{ required: true, message: '请输入SMTP端口' }] },\n            ]"}],attrs:{"placeholder":"请输入SMTP端口"}})],1),_c('a-form-item',{attrs:{"label":"服务邮箱帐号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sendUserName',
              { rules: [{ required: true, message: '请输入服务邮箱帐号' }] } ]),expression:"[\n              'sendUserName',\n              { rules: [{ required: true, message: '请输入服务邮箱帐号' }] },\n            ]"}],attrs:{"placeholder":"请输入服务邮箱帐号"}})],1),_c('a-form-item',{attrs:{"label":"服务邮箱密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sendPassword',
              { rules: [{ required: true, message: '请输入服务邮箱密码' }] } ]),expression:"[\n              'sendPassword',\n              { rules: [{ required: true, message: '请输入服务邮箱密码' }] },\n            ]"}],attrs:{"placeholder":"请输入服务邮箱密码","type":"password"}})],1),_c('a-form-item',{attrs:{"label":"发送方邮件地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sendAddress',
              { rules: [{ required: true, message: '请输入发送方邮件地址' }] } ]),expression:"[\n              'sendAddress',\n              { rules: [{ required: true, message: '请输入发送方邮件地址' }] },\n            ]"}],attrs:{"placeholder":"请输入发送方邮件地址"}})],1),_c('a-form-item',{attrs:{"label":"邮件主题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'subject',
              { rules: [{ required: true, message: '请输入邮件主题' }] } ]),expression:"[\n              'subject',\n              { rules: [{ required: true, message: '请输入邮件主题' }] },\n            ]"}],attrs:{"placeholder":"请输入邮件主题"}})],1),_c('a-form-item',{attrs:{"label":"接收方邮件地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'receiveAddress',
              { rules: [{ required: true, message: '请输入接收方邮件地址' }] } ]),expression:"[\n              'receiveAddress',\n              { rules: [{ required: true, message: '请输入接收方邮件地址' }] },\n            ]"}],attrs:{"placeholder":"请输入接收方邮件地址"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.handleReset}},[_vm._v("重置")]),_vm._v(" "),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"html-type":"button","type":"primary"},on:{"click":_vm.test}},[_vm._v("发送邮件")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }