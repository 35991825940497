import request from '@/utils/request'

const api = {
  saveUrl: '/admincp/settings/save',
  getSettingUrl: '/admincp/settings/index',
  testEmail: '/admincp/settings/test'
}

export function save (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.saveUrl,
    method: 'post',
    data: parameter
  })
}

export function getSetting (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.getSettingUrl,
    method: 'post',
    data: parameter
  })
}
export function getTestEmail (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.testEmail,
    method: 'post',
    data: parameter
  })
}
