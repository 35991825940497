<template>
  <a-card :bordered="false">
    <page-header-wrapper :Style="{ padding: '3px 10px'}" :title="false">  </page-header-wrapper>
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      layout="vertical"
      @submit="handleSubmit">
      <a-row :gutter="16">
        <a-col :lg="16" :md="24">
          <a-form-item
            label="SMTP服务器"
          >
            <a-input
              v-decorator="[
                'smtpIp',
                { rules: [{ required: true, message: '请输入邮件服务器IP地址' }] },
              ]"
              placeholder="请输入SMTP服务器"/>
          </a-form-item>
          <a-form-item
            label="SMTP服务器端口"
          >
            <a-input
              v-decorator="[
                'smtpPort',
                { rules: [{ required: true, message: '请输入SMTP端口' }] },
              ]"
              placeholder="请输入SMTP端口"/>
          </a-form-item>

          <a-form-item
            label="服务邮箱帐号"
          >
            <a-input
              v-decorator="[
                'sendUserName',
                { rules: [{ required: true, message: '请输入服务邮箱帐号' }] },
              ]"
              placeholder="请输入服务邮箱帐号"/>
          </a-form-item>
          <a-form-item
            label="服务邮箱密码"
          >
            <a-input
              v-decorator="[
                'sendPassword',
                { rules: [{ required: true, message: '请输入服务邮箱密码' }] },
              ]"
              placeholder="请输入服务邮箱密码"
              type="password"/>
          </a-form-item>
          <a-form-item
            label="发送方邮件地址"
          >
            <a-input
              v-decorator="[
                'sendAddress',
                { rules: [{ required: true, message: '请输入发送方邮件地址' }] },
              ]"
              placeholder="请输入发送方邮件地址"/>
          </a-form-item>
          <a-form-item
            label="邮件主题"
          >
            <a-input
              v-decorator="[
                'subject',
                { rules: [{ required: true, message: '请输入邮件主题' }] },
              ]"
              placeholder="请输入邮件主题"/>
          </a-form-item>
          <a-form-item
            label="接收方邮件地址"
          >
            <a-input
              v-decorator="[
                'receiveAddress',
                { rules: [{ required: true, message: '请输入接收方邮件地址' }] },
              ]"
              placeholder="请输入接收方邮件地址"/>
          </a-form-item>
          <a-form-item>
            <a-button html-type="submit" type="primary">保存</a-button>
            <a-button style="margin-left: 8px" @click="handleReset">重置</a-button>      <a-button style="margin-left: 8px" html-type="button" type="primary" @click="test">发送邮件</a-button>
          </a-form-item>

        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>

<script>
import { getSetting, getTestEmail, save } from '@/api/setting'
import pick from 'lodash.pick'

export default {
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.handleSettingInfo()
  },
  methods: {
    handleSettingInfo () {
      this.$nextTick(function () {
        const fields = ['smtpIp', 'smtpPort', 'sendUserName', 'sendPassword', 'sendAddress', 'subject', 'receiveAddress']

        getSetting().then(res => {
            this.form.setFieldsValue(pick(res.result, fields))
          }
        )
      })
    },
    setavatar (url) {
      this.option.img = url
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const self = this
          // 修改 e.g.
          save(values)
            .then(res => {
              if (res.code === 1) {
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }
      })
    },
    handleReset () {
      this.form.resetFields()
    },
    test () {
      this.form.validateFields((err, values) => {
        console.log(values)
        getTestEmail(values)
          .then(res => {
            if (res.code === 1) {
              self.$message.info(res.message)
            } else {
              self.$message.error(res.message)
            }
          })
      })
    }
  }
}
</script>
